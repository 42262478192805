<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
  mounted() {
    // window.scrollTo(0,1);

  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body {
  background-color: rgb(40, 40, 40);
}
button {
    font-size: 20px;
    color: rgb(200, 200, 200);
    background-color: rgb(70, 70, 70);
    border: 3px solid rgb(100, 100, 100);
    border-radius: 5px;
    padding: 10px;
}
button:hover {
    color: white;
    cursor: pointer;
}
button:active {
    background-color: rgb(20, 20, 20);
}

@font-face {
  font-family: NotoMono;
  src: url('../public/fonts/NotoMono-Regular.ttf');
}
</style>
