import  { createApp } from 'vue';
import App from './App.vue'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from './routes'

const router = createRouter({
    routes,
    history: createWebHistory(), 
    scrollBehavior (to, from, savedPosition) {
        return savedPosition
    }
})

const app = createApp(App)
app.use(router)
app.mount('#app')

