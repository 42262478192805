<template>
    <div>
        <h1>ShutterSort Image Demo</h1>
        <img class="logo-image" src="data.jpg" alt='[none]'>
        <div class="form-div">
            <form class="login-form">
                <input type="text" placeholder="Username" name="user" v-model.trim="user">
                <input type="password" placeholder="Password" name="password" v-model.trim="password">
                <div class="row">
                    <button @click.prevent="submitted" @click=login()>Login</button>
                </div>
            </form>
        </div>
    </div>
    
</template>

<script>
import axios from 'axios';
export default {
    data() {
        return {
            user: null,
            password: null
        }
    },
    methods: {
        login() {
            const payload = {email: this.user, password: this.password, returnSecureToken: true};
            axios({
                method: 'post',
                url: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword?key=AIzaSyBXx0QtZbw4DzXetRmCJjH-nDe0kURkH9c',
                data: payload
            }).then(response => {
                sessionStorage.setItem('localId', response.data.localId);
                sessionStorage.setItem('idToken', response.data.idToken);
                sessionStorage.setItem('refreshToken', response.data.refreshToken);
                this.$router.push('/');
                document.body.requestFullscreen();
            }).catch(() => alert('You messed up 👎'));
        }
    }
}
</script>

<style scoped>
    .logo-image {
        width: 250px;
        height: 250px;
    }
        
    h1 {
        color: rgb(200, 200, 200);
    }

    .form-div {
        display: block;
        text-align: center;
        margin: 10px;
    }

    .login-form {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    input {
        width: 65%;
        height: 35px;
        margin: 5px;
        background-color: rgb(150, 150, 150);
        color: black;
        border-radius: 5px;
    }

    button {
        margin: 5px;
        padding: 5px;
    }

</style>