import LoginPage from './pages/LoginPage.vue'
import DemoCodePage from './pages/DemoCodePage.vue'


/**
 * Check to make sure that we're authenticated before accessing a protected route
 */

function authenticate(to, from, next) {
    if (sessionStorage.getItem('idToken') !== null) {
        next();
    } else {
        next('/login');
    }
}


export const routes = [
    {
        path: '/login', 
        component: LoginPage
    },
    {
        path: '/', 
        component: DemoCodePage, 
        beforeEnter: authenticate
    }
]
