<template>
    <div>
        <image-code-page v-bind:firstName="firstName"
                         v-bind:lastName="lastName"
                         v-bind:id="id"
                         v-bind:code="code"
                         v-bind:groups="groups"/>
    </div>
</template>

<script>
import ImageCodePage from './ImageCodePage.vue';

export default {
    components: {
        ImageCodePage
    },
    data() {
        return {
            firstName: '',
            lastName: '',
            id: '',
            code: '',
            groups: {},
            firstNames : ['Liam', 'Olivia', 'Noah', 'Emma',
                          'Oliver', 'Ava', 'William', 'Sophia',
                          'Elijah', 'Isabella', 'James', 'Charlotte',
                          'Benjamin', 'Amelia', 'Lucas', 'Mia',
                          'Mason', 'Harper', 'Ethan', 'Evelyn',
                          'Alexander', 'Abigail', 'Henry', 'Emily',
                          'Jacob', 'Ella', 'Michael', 'Elizabeth',
                          'Daniel', 'Camila', 'Logan', 'Luna',
                          'Jackson', 'Sofia', 'Sebastian', 'Avery',
                          'Jack', 'Mila', 'Aiden', 'Aria',
                          'Owen', 'Scarlett', 'Samuel', 'Penelope',
                          'Matthew', 'Layla', 'Joseph', 'Chloe',
                          'Levi', 'Victoria', 'Mateo', 'Madison',
                          'David', 'Eleanor', 'John', 'Grace',
                          'Wyatt', 'Nora', 'Carter', 'Riley',
                          'Julian', 'Zoey', 'Luke', 'Hannah',
                          'Grayson', 'Hazel', 'Isaac', 'Lily',
                          'Jayden', 'Ellie'],
            lastNames : ['Smith', 'Johnson', 'Williams', 'Brown',
                         'Jones', 'Garcia', 'Miller', 'Davis',
                         'Rodriguez', 'Martinez', 'Hernandez', 'Lopez',
                         'Gonzales', 'Wilson', 'Anderson', 'Thomas',
                         'Taylor', 'Moore', 'Jackson', 'Martin',
                         'Lee', 'Perez', 'Thompson', 'White',
                         'Harris', 'Sanchez', 'Clark', 'Ramirez',
                         'Lewis', 'Robinson', 'Walker', 'Young',
                         'Allen', 'King', 'Wright', 'Scott',
                         'Torres', 'Nguyen', 'Hill', 'Flores',
                         'Green', 'Adams', 'Nelson', 'Baker',
                         'Hall', 'Rivera', 'Campbell', 'Mitchell',
                         'Carter', 'Roberts', 'Gomez', 'Phillips',
                         'Evans', 'Turner', 'Diaz', 'Parker',
                         'Cruz', 'Edwards', 'Collins', 'Reyes',
                         'Stewart', 'Morris', 'Morales', 'Murphy',
                         'Cook', 'Rogers', 'Gutierrez', 'Oritz',
                         'Morgan', 'Cooper'],
            schools: ["St. Christopher's", 'Steward', 'Collegiate',
                      "St. Catherine's", 'Trinity', 'Benedictine',
                      'Tucker', 'Deep Run', 'Godwin', 'Hermitage',
                      'Manchester', 'James River', 'Atlee'],
            departments: ['Accounting', 'Sales', 'Finance', 'HR',
                          'Engineering', 'Facilities', 'Billing']
        }
    },
    mounted() {
        window.setInterval(() => this.updateData(), 1000);
    },
    methods: {

        updateData() {
            
            const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');
            this.firstName = this.firstNames[Math.floor(Math.random() * this.firstNames.length)];
            this.lastName = this.lastNames[Math.floor(Math.random() * this.lastNames.length)];
            const symbols = [];
            for (var i = 0; i < 64; i++) {
                var symbol = 'x';
                const rand = Math.random();
                if (rand > 0.25 && rand <= 0.5) {
                    symbol = 'o';
                } else if (rand > 0.5 && rand <= 0.75) {
                    symbol = '|';
                } else if (rand > 0.75 && rand <= 1.0) {
                    symbol = '-';
                }
                symbols.push(symbol);
            }
            this.code = symbols.join('');
            const idChars = [];
            for (i = 0; i < 11; i++) {
                idChars.push(charset[Math.floor(Math.random() * charset.length)]);
            }
            this.id = idChars.join('');
            switch (Math.round(Math.ceil(Math.random() * 3 + 0.5))) {
                case 1: {
                    this.groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                    }
                    break;
                }
                case 2: {
                    this.groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                        'Grade': Math.floor(Math.random() * 12) + 1,
                    }
                    break;
                }
                case 3:
                    this.groups = {
                        'Advisor': this.lastNames[Math.floor(Math.random() * this.lastNames.length)],
                        'Grade': Math.floor(Math.random() * 12) + 1,
                        'School': this.schools[Math.floor(Math.random() * this.schools.length)]
                    }
                    break;
                case 4:
                    this.groups = {
                        'Department': this.departments[Math.floor(Math.random() * this.departments.length)],
                        'Manager': this.lastNames[Math.floor(Math.random() * this.lastNames.length)]
                    }
                    break;
                case 5:
                    this.groups = {
                        'Manager': this.lastNames[Math.floor(Math.random() * this.lastNames.length)]
                    }
                    break;
            }
            
        }
    }
}
</script>ImageCodePage